<template>
  <div>
    <div class="moto-bg">
      <div class="nav">
        <a href="/" target="_self"
          ><img
            src="../assets/images/ui/logo.png"
            style="margin-left: -150px; max-width: 250px"
        /></a>
        <div class="menu details">
          <a href="/" target="_self">Accueil</a>
          <a href="/moto" target="_blank">Symone à moto</a>
          <a href="/news" target="_blank">Actus</a>
          <a href="/contact" target="_blank"
            >Contact</a
          >
          <a href="/faq" target="_blank">FAQ</a>
          <a href="/" target="_blank"
            ><img
              src="../assets/images/ui/france.png"
              style="width: 25px; height: 25px"
          /></a>
          <a href="/en" target="_blank"
            ><img
              src="../assets/images/ui/royaume-uni.png"
              style="width: 25px; height: 25px"
          /></a>
        </div>
      </div>
      <div class="about1" style="text-align: -webkit-center; padding: 50px">
        <div class="title">
          <span style="color: #e6007e">P</span>rofitez de votre temps, faites
          des économies
        </div>
        <div class="title" style="font-size: 30px; margin-top: -10px">
          <span style="color: #e6007e">F</span>ini les contraintes à moto sur
          l'autoroute !
        </div>
        <figure class="wp-block-video" style="width: 100%">
          <video
            id="videoSymoneMoto"
            style="border-radius: 10px; max-height: 50vh; max-width: 100%;"
            src="../assets/videos/Publicite-Symone-moto-V12.mp4"
            autoplay="autoplay"
            loop="loop"
            muted=""
            controls="controls"
          ></video>
        </figure>
        <br />
        <div
          class="border"
          style="
            max-width: 80%;
            color: white;
            font-size: 20px;
            margin-bottom: 30px;
          "
        >
          Confiez à Symone votre voyage et vos trajets longue distance sur autoroutes. Vous et votre moto arriverez à destination dans les meilleures conditions, au meilleur prix, pour profiter du plaisir de piloter là où le meilleur de votre passion peut s'exercer. Transport de moto sans pilote possible. 
<br><br>
Votre moto est assurée pendant toute la durée du trajet en Symone. 
        </div>
        <a href="/gift" target="_blank"
          ><button
            class='btn-rose'
          >
            Offrez un bon cadeau Symone moto pour vos proches motards !
          </button></a
        >
      </div>
      <div class="tags">
        <button class="tag" :style="tagBg1" @click="switchTab(1)">
          <div>Présentation</div>
        </button>
        <button class="tag tag-child" :style="tagBg2" @click="switchTab(2)">
          <div>Nos Trajets</div>
        </button>
        <!-- <button class="tag tag-child" :style="tagBg3" @click="switchTab(3)">
          <div>Mes trajets</div>
        </button>
        <button class="tag tag-child" :style="tagBg4" @click="switchTab(4)">
          <div>Mes devis</div>
        </button>
        <button class="tag tag-child" :style="tagBg5" @click="switchTab(5)">
          <div>Mon compte</div>
        </button> -->
      </div>
      <div class="submenu"></div>
      <div v-if="currentTab !== 2" class="submenu-bottom"></div>
      <div v-if="currentTab == 2" class="submenu-bottom">
        <div
          class="submenu-option w-2"
          :style="subMenuTrajectory"
          @click="
            currentSubMenuTrajectory = true;
            subMenuTrajectory = 'color: #44bbc3';
            subMenuDevis = 'color: white';
          "
        >
          - Trajets -
        </div>
        <div
          id='tab-devis'
          class="submenu-option w-2"
          :style="subMenuDevis"
          @click="
            currentSubMenuTrajectory = false;
            subMenuDevis = 'color: #44bbc3';
            subMenuTrajectory = 'color: white';
          "
        >
          - Demande devis -
        </div>
      </div>
      <div class="window">
        <div v-if="currentTab == 1">
          <div class="about2">
            <div class="title border">
              <span style="color: #e6007e">U</span>n voyage, un roadtrip, un circuit ?
<br>
Symone vous transporte avec votre moto aux portes de vos terrains de jeux
            </div>
            <div
              style="
                max-width: 1000px;
                margin-top: 60px;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
              "
            >
              <video
                id="videoSymoneMoto"
                style="border-radius: 10px; width: 40%"
                src="../assets/videos/Publicite-Symone-moto-V12.mp4"
                autoplay="autoplay"
                loop="loop"
                muted=""
                controls="controls"
              ></video>
              <div style="width: 70%; margin: 20px">
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 40px"
                >
                  <span style="color: #e6007e">P</span>ratique
                </div>
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 20px; line-height: 1"
                >
                  En amont du réseau autoroutier, votre moto est chargée et amarrée en toute sécurité sur une remorque spécialement aménagée pour le transport de motos. Ensuite installez-vous confortablement et profitez du voyage ! 
                </div>
              </div>
            </div>
            <div
              style="
                max-width: 1000px;
                flex-direction: row-reverse;
                margin-top: 60px;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
              "
            >
              <img
                src="../assets/images/symonemoto4-scaled-e1693995436192.jpg"
                style="border-radius: 10px; max-width: 40%; max-height: 250px"
              />
              <div style="width: 65%; margin: 20px">
                <div
                  class="title"
                  style="text-align-last: right; font-size: 40px"
                >
                  <span style="color: #e6007e">U</span>ne expérience inédite
                </div>
                <div
                  class="title"
                  style="
                    text-align-last: right;
                    font-size: 20px;
                    line-height: 1;
                  "
                >
                  Vous voyagez dans un van tout confort conduit par un
                  professionnel. Vous arrivez à destination en toute sécurité,
                  de manière plus décarbonée et en profitant enfin de toutes ces
                  longues heures sur l'autoroute que vous passiez derrière le
                  guidon.
                </div>
              </div>
            </div>
            <div
              style="
                max-width: 1000px;
                margin-top: 60px;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
              "
            >
              <img
                src="../assets/images/symonemoto1-scaled.jpg"
                style="border-radius: 10px; max-width: 40%; max-height: 250px"
              />
              <div style="width: 65%; margin: 20px">
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 40px"
                >
                  <span style="color: #e6007e">U</span>n service pour tous
                </div>
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 20px; line-height: 1"
                >
                  À l'arrivée, récupérez instantanément votre moto et
                  faites-vous plaisir sur les plus belles routes. Symone propose
                  ses services en journée et la nuit, pour les professionnels et
                  les particuliers. Votre moto est assurée pendant toute la
                  durée du trajet en Symone.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentTab == 2">
          <div class="trajectory">
            <div class="trajectory-search">
              <a class="trajectory-search-partners" v-for="(row, index) in partnersLogo" :key="index" :href="row.link" target="_blank">
                <img :src='row.src' width=200 :title="row.alt" :alt="row.alt" style="margin-bottom: 80px">
              </a>
              <div class='trajectory-search-container'>
              <div class="flex-col">
                <div class="number">1</div>
                <div class="border">
                  <span style="color: #e6007e">V</span>otre souhait de trajet
                </div>
              </div>
              <div class="trajectory-search-options">
                <div class="trajectory-search-options-card">
                  <el-form
                    :model="search"
                    label-width="auto"
                    style="max-width: 600px"
                  >
                    <el-form-item>
                      <Autocomplete :key='refreshAutocomplete' :required="true" 
                        class="autocomplete"
                        v-model="search.depart"
                        placeholder="Ville de départ"
                      ></Autocomplete>
                    </el-form-item>
                    <el-form-item>
                      <Autocomplete :key='refreshAutocomplete' :required="true" 
                        class="autocomplete"
                        v-model="search.arrival"
                        placeholder="Destination"
                      ></Autocomplete>
                    </el-form-item>
                    <el-form-item>
                      <el-date-picker
                        v-model="search.date"
                        type="date"
                        value-format="YYYYMMDD"
                        style="width: 100%"
                        placeholder="Date de départ"
                      />
                    </el-form-item>
                  </el-form>
                  <button
                    class="btn-rose"
                    @click="searchCities"
                  >
                    Rechercher
                  </button>
                </div>
              </div>
              </div>
            </div>
            <div v-if="currentSubMenuTrajectory" class="trajectory-results">
              
              <div class="loading-container" v-if="isLoading">
                <div class="loading-spinner"></div>
              </div>
              <div class="flex" id='results'>
                <div class="number">2</div>
                <div class="border" style='text-align: center;'>
                  <span style="color: #e6007e">S</span>électionnez un trajet à
                  compléter OU
                  <span
                    class='border-white'
                    style="color: #e6007e; cursor: pointer"
                    @click="
                      currentSubMenuTrajectory = false;
                      subMenuDevis = 'color: #44bbc3';
                      subMenuTrajectory = 'color: white';
                    "
                    ><u>Demandez un devis</u></span
                  >
                </div>
              </div><br>
              <el-pagination background layout="prev, pager, next" :total="totalItems" :page-size="pageSize" @current-change="handleCurrentChange" v-model:current-page="currentPage"/>
              
              <div class="results">
                <div class="flex">
                  <el-affix target=".results" :offset="10" position="bottom">
                    <el-button type="primary" color="#000047" @click="getResults()">Afficher tous les trajets</el-button>
                  </el-affix>
                  <el-affix target=".results" :offset="10" position="bottom">
                    <el-button type="primary" color="#44bbc3" @click="backTop()">TOP↑</el-button>
                  </el-affix>
                </div>
                <div class='result-container' v-for="(row, index) in displayedItems" :key="index">
                <div v-if='row.first' class='hr-line border'><hr><br>Ancien(s) trajet(s)<br><br><hr></div>
                <div v-if='row.type == "trajectory"' class="card-trajectory">
                  <div v-if='!checkDate(row.date)' class="overlay-blue"><div>Réalisé</div></div>
                  <div v-if='checkDate(row.date) && row.completed' class="overlay-blue"><div>Complet</div></div>
                  <div class="card-trajectory-header">
                    <div class="card-trajectory-header-date">
                      {{ fullDateFR(row.date) }}
                    </div>
                    <div v-if="!row.completed && checkDate(row.date)" class="card-trajectory-header-info">
                      <div class="card-trajectory-header-info-moto">
                        Motos<br />{{row.moto_reserved}} / {{row.moto_max}}
                      </div>
                      <div class="card-trajectory-header-info-passenger">
                        Voyageurs<br />{{row.passenger_reserved}} / {{row.passenger_max}}
                      </div>
                    </div>
                  </div>
                  <div class="card-trajectory-info">
                    <div v-if="row.discount_value && checkDate(row.date) && !row.completed" class="card-discount">-{{row.discount_value}}{{row.discount_type == '1' ? '%' : "€"}} immédiatement</div>
                    <div class="card-trajectory-info-first">
                      <div class="card-trajectory-info-trajectory">
                        <b><span style="color: black">{{row.depart.split(',')[1].trim() == 'France' ? row.depart.split(',')[0] : row.depart.split(',')[0] + '(' + showCountryAbbreviation(row.depart.split(',')[1]) + ')'}}</span></b>
                        <span class="rose" style="font-size: 20px; display: contents;"
                          ><span v-if='row.layover1' class="material-symbols-outlined rose">east</span>
                          {{row.layover1 ? row.layover1.split(',')[1].trim() == 'France' ? row.layover1.split(',')[0] + '(*)' : row.layover1.split(',')[0] + '(' + showCountryAbbreviation(row.layover1.split(',')[1]) + ')' + '(*)' : ''}}
                          <span v-if='row.layover2' class="material-symbols-outlined rose">east</span>
                          {{row.layover2 ? row.layover2.split(',')[1].trim() == 'France' ? row.layover2.split(',')[0] + '(*)' : row.layover2.split(',')[0] + '(' + showCountryAbbreviation(row.layover2.split(',')[1]) + ')' + '(*)' : ''}}
                          <span v-if='row.layover3' class="material-symbols-outlined rose">east</span>
                          {{row.layover3 ? row.layover3.split(',')[1].trim() == 'France' ? row.layover3.split(',')[0] + '(*)' : row.layover3.split(',')[0] + '(' + showCountryAbbreviation(row.layover3.split(',')[1]) + ')' + '(*)' : ''}}
                          <span v-if='row.layover1 || row.layover2 || row.layover3' class="material-symbols-outlined rose">east</span>
                        </span>
                        <span v-if='!row.layover1 && !row.layover2 && !row.layover3' class="material-symbols-outlined rose">east</span><b><span style="color: black">{{ row.arrival.split(',')[1].trim() == 'France' ? row.arrival.split(',')[0]  : row.arrival.split(',')[0] + '(' + showCountryAbbreviation(row.arrival.split(',')[1]) + ')'}}</span></b>
                      </div>
                      <div class="card-trajectory-info-services">
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise 220v"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prise 220v"
                              >power</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise USB"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prises USB"
                              >usb</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Café"
                        >
                          <template #reference>
                            <span class="material-symbols-outlined" title="Café"
                              >coffee</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Jeux de société"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Jeux de société"
                              >playing_cards</span
                            >
                          </template>
                        </el-popover>
                      </div>
                    </div>
                    <div v-if="row.layover1 || row.layover2 || row.layover3" class="card-trajectory-info-second rose">
                      *Arrêt(s) facultatif(s)
                    </div>
                  </div>
                  <el-collapse v-model="collapseStatus[row.id]">
                    <el-collapse-item name="1">
                      <template #title>
                        <div class="rose" style='min-width: fit-content;'>
                          Sur demande, autres trajets possibles sur ce parcours
                          (autres villes de chargement et/ou de déchargement).
                        </div>
                        <div style="width: -webkit-fill-available;"></div>
                        <button class="btn-blue">Détails</button>
                      </template>
                      <div class="trajectory-plan" v-if="!row.completed && checkDate(row.date)">
                        <el-timeline
                          style="
                            width: 200px;
                            background: white;
                            border-radius: 20px;
                          "
                        >
                        <div class="timeline-bloc" v-if="row.time0">
                          <div class="time">{{formatTimestamp(row.time0, row.moto_reserved)}}</div>
                          <el-timeline-item
                            color="#44bbc3"
                            size="large"
                          >
                            <div>Début chargement {{row.moto_reserved && row.moto_reserved >3 ? "30" : "15"}} minutes avant le départ</div>
                          </el-timeline-item>
                        </div>
                        <div class="timeline-bloc" v-if="row.depart">
                          <div v-if="row.time0" class="time">{{row.time0}}</div>
                          <div v-else class="time white" >00:00</div>
                          <el-timeline-item
                            color="black"
                            size="large"
                          >
                            <a v-if="row.address_depart" :title="row.address_depart" :href="`https://www.google.com/maps?q=${row.address_depart}`" target="_blank"><u><b>{{row.depart.split(',')[0]}}</b></u></a><div v-else><b>{{row.depart.split(',')[0]}}</b></div>
                            
                            <div class="timeline-comment" v-if="row.address_depart">Lien cliquable adresse</div>
                          </el-timeline-item>
                        </div>
                        <div class="timeline-bloc" v-if="row.layover1">
                          <div v-if="row.time1" class="time">{{row.time1}}</div>
                          <div v-else class="time white" >00:00</div>
                          <el-timeline-item
                            color="#E6007E"
                            size="normal"
                          >
                          <a v-if="row.address_layover1" :title="row.address_layover1" :href="`https://www.google.com/maps?q=${row.address_layover1}`" target="_blank">
                            <span class="rose"
                              ><u><b>{{row.layover1.split(',')[0]}}</b></u></span
                            ></a><div v-else><span class="rose"
                              ><b>{{row.layover1.split(',')[0]}}</b></span
                            ></div>
                            <div class="timeline-comment" v-if="row.address_layover1">Lien cliquable adresse</div>
                          </el-timeline-item>
                        </div>

                        <div class="timeline-bloc" v-if="row.layover2">
                          <div v-if="row.time2" class="time">{{row.time2}}</div>
                          <div v-else class="time white" >00:00</div>
                          <el-timeline-item
                            color="#E6007E"
                            size="normal"
                          ><a v-if="row.address_layover2" :title="row.address_layover2" :href="`https://www.google.com/maps?q=${row.address_layover2}`" target="_blank">
                            <span class="rose"
                              ><u><b>{{row.layover2.split(',')[0]}}</b></u></span
                            ></a><div v-else><span class="rose"
                              ><b>{{row.layover2.split(',')[0]}}</b></span
                            ></div>
                            <div class="timeline-comment" v-if="row.address_layover2">Lien cliquable adresse</div>
                          </el-timeline-item>
                        </div>
                        <div class="timeline-bloc" v-if="row.layover3">
                          <div v-if="row.time3" class="time">{{row.time3}}</div>
                          <div v-else class="time white" >00:00</div>
                          <el-timeline-item
                            color="#E6007E"
                            size="normal"
                          ><a v-if="row.address_layover3" :title="row.address_layover3" :href="`https://www.google.com/maps?q=${row.address_layover3}`" target="_blank">
                            <span class="rose"
                              ><u><b>{{row.layover3.split(',')[0]}}</b></u></span
                            >
                          </a><div v-else><span class="rose"
                              ><b>{{row.layover3.split(',')[0]}}</b></span
                            ></div>
                            <div class="timeline-comment" v-if="row.address_layover3">Lien cliquable adresse</div>
                          </el-timeline-item>
                        </div>
                        <div class="timeline-bloc"  v-if="row.arrival">
                          <div v-if="row.time4" class="time">{{row.time4}}</div>
                          <div v-else class="time white" >00:00</div>

                          <el-timeline-item
                            color="black"
                            size="large"
                          >
                          <a v-if="row.address_arrival" :title="row.address_arrival" :href="`https://www.google.com/maps?q=${row.address_arrival}`" target="_blank">
                            <u><b>{{row.arrival.split(',')[0]}}</b></u></a><div v-else><b>{{row.arrival.split(',')[0]}}</b></div>
                            
                            <div class="timeline-comment" v-if="row.address_arrival">Lien cliquable adresse</div>
                          </el-timeline-item>
                        </div>
                        </el-timeline>
                        <div class="trajectory-options">
                          <div class="trajectory-options-discount">
                            <div v-if="row.discount_value" class="card-discount-red">-{{row.discount_value}}{{row.discount_type == '1' ? '%' : "€"}} immédiatement</div>
                            <div v-if="row.discount_group_value && row.discount_group_moto" class="card-discount-blue">Tarif groupe: -{{row.discount_group_value}}% pour ≥ {{row.discount_group_moto}} motos</div>
                            <span
                              @click="showHelpPopup = true"
                              style="cursor: pointer; font-size: 40px;"
                              class="material-symbols-outlined"
                              title="Comment ça fonctionne ?"
                              >help</span
                            >
                          </div>
                          <b>- Veuillez choisir votre trajet -</b>
                          <br>
                          <div v-if="row.price0_4 && row.depart && row.arrival">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="0-4"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price0_4) == false'> {{row.depart.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <b>{{ row.price0_4 }}</b>{{ /^\d+$/.test(row.price0_4) ? '€ TTC': ''}}</p>
                            <p v-else> {{row.depart.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <s>{{ row.price0_4 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price0_4) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price0_4) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price0_1 && row.depart && row.layover1">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="0-1"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price0_1) == false'>{{row.depart.split(',')[0]}} - {{row.layover1.split(',')[0]}} : <b>{{ row.price0_1 }}</b>{{ /^\d+$/.test(row.price0_1) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.depart.split(',')[0]}} - {{row.layover1.split(',')[0]}} : <s>{{ row.price0_1 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price0_1) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price0_1) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price0_2 && row.depart && row.layover2">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="0-2"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price0_2) == false'>{{row.depart.split(',')[0]}} - {{row.layover2.split(',')[0]}} : <b>{{ row.price0_2 }}</b>{{ /^\d+$/.test(row.price0_2) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.depart.split(',')[0]}} - {{row.layover2.split(',')[0]}} : <s>{{ row.price0_2 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price0_2) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price0_2) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price0_3 && row.depart && row.layover3">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="0-3"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price0_3) == false'>{{row.depart.split(',')[0]}} - {{row.layover3.split(',')[0]}} : <b>{{ row.price0_3 }}</b>{{ /^\d+$/.test(row.price0_3) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.depart.split(',')[0]}} - {{row.layover3.split(',')[0]}} : <s>{{ row.price0_3 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price0_3) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price0_3) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price1_2 && row.layover1 && row.layover2">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="1-2"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price1_2) == false'>{{row.layover1.split(',')[0]}} - {{row.layover2.split(',')[0]}} : <b>{{ row.price1_2 }}</b>{{ /^\d+$/.test(row.price1_2) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.layover1.split(',')[0]}} - {{row.layover2.split(',')[0]}} : <s>{{ row.price1_2 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price1_2) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price1_2) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price1_3 && row.layover1 && row.layover3">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="1-3"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price1_3) == false'>{{row.layover1.split(',')[0]}} - {{row.layover3.split(',')[0]}} : <b>{{ row.price1_3 }}</b>{{ /^\d+$/.test(row.price1_3) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.layover1.split(',')[0]}} - {{row.layover3.split(',')[0]}} : <s>{{ row.price1_3 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price1_3) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price1_3) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price1_4 && row.layover1 && row.arrival">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="1-4"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price1_4) == false'>{{row.layover1.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <b>{{ row.price1_4 }}</b>{{ /^\d+$/.test(row.price1_4) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.layover1.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <s>{{ row.price1_4 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price1_4) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price1_4) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price2_3 && row.layover2 && row.layover3">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="2-3"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price2_3) == false'>{{row.layover2.split(',')[0]}} - {{row.layover3.split(',')[0]}} : <b>{{ row.price2_3 }}</b>{{ /^\d+$/.test(row.price2_3) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.layover2.split(',')[0]}} - {{row.layover3.split(',')[0]}} : <s>{{ row.price2_3 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price2_3) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price2_3) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price2_4 && row.layover2 && row.arrival">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="2-4"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price2_4) == false'>{{row.layover2.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <b>{{ row.price2_4 }}</b>{{ /^\d+$/.test(row.price2_4) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.layover2.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <s>{{ row.price2_4 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price2_4) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price2_4) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div v-if="row.price3_4 && row.layover3 && row.arrival">
                            <input
                              type="radio"
                              :name="'optionPrice-' + row.id"
                              value="3-4"
                            />
                            <p v-if='!row.discount_value || /^\d+$/.test(row.price3_4) == false'>{{row.layover3.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <b>{{ row.price3_4 }}</b>{{ /^\d+$/.test(row.price3_4) ? '€ TTC': ''}}</p>
                            <p v-else>{{row.layover3.split(',')[0]}} - {{row.arrival.split(',')[0]}} : <s>{{ row.price3_4 }}</s><span style='color: #c50909;'><b>{{ row.discount_type == '1' ? parseInt(row.price3_4) * (1 - (parseInt(row.discount_value) / 100)) : (parseInt(row.price3_4) - parseInt(row.discount_value)) }}</b>€</span> TTC</p>
                          </div>
                          <div>
                            <input type="radio" :name="'optionPrice-' + row.id" value="other" checked/>
                            Autre trajet souhaité sur ce parcours :
                            <b>Demande de devis</b>
                          </div>
                          <br>
                          <button class="btn-rose w-1" @click='openReservationPopup(row)'>
                            Je pré-réserve
                          </button>
                        </div>
                      </div>
                      <div v-if="row.eco && row.eco !='<p><br></p>' && !row.completed && checkDate(row.date)" class="trajectory-eco" v-html="row.eco"></div>
                      
                    </el-collapse-item>
                  </el-collapse>
                </div>


                
                <el-card v-if="row.type == 'event' && checkDate(row.date)" style="max-width: 500px; margin: 30px 0;">
                  <template #header>
                    <div class="card-header">
                      <span style="font-size: 20px;">{{fullDateFR(row.date)}}</span>
                      <button class='btn-blue' @click='openReservationPopup(row)'>Devis</button>
                    </div>
                  </template>
                  <div class='card-title'>
                    <img v-if="row.img" :src='row.img' style="max-width: 30%;">
                    <span style="font-size: 20px"><b>{{row.name}}{{row.location ? '(' + row.location + ')' : ''}}</b></span>
                  </div>
                  <template #footer><p style="font-size: 15px;">{{row.details}}</p></template>
                </el-card>

                
              </div>
              <el-pagination background layout="prev, pager, next" :total="totalItems" :page-size="pageSize" @current-change="handleCurrentChange" v-model:current-page="currentPage"/>
            </div>
            <div class="partners w-1">
              <div class="white">NOS PARTENAIRES</div>
            </div>
            <div class="w-1 partners-list">
              <a v-for="(row, index) in partnersLogo" :key="index" :href="row.link" target="_blank">
                <img :src='row.src' width=200 :title="row.alt" :alt="row.alt">
              </a>
            </div>
            <hr class="w-1">
            <div class="footer">
          <div style="margin: 50px">
            <div class="footer-title">
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
        </div>
            </div>

            <div v-if="!currentSubMenuTrajectory" class="trajectory-results">
              <div class="flex-col" id='devis-title'>
                <div class="flex">
                  <div class="number">2</div>
                  <div class="border" style='text-align: center;'>
                    <span style="color: #e6007e">A</span>ucun trajet
                    correspondant, demandez un devis
                  </div>
                </div>
                <el-form
                  ref="ruleDevisRef"
                  style="max-width: 600px"
                  :model="formDevis"
                  :rules="rulesDevis"
                  label-width="auto"
                  label-position="top"
                  class="form"
                  status-icon
                >
                  <div>
                    <el-form-item label="Trajet:" required prop='type'>
                      <el-radio-group v-model="formDevis.type">
                        <el-radio value="oneWay">Aller simple</el-radio>
                        <el-radio value="roundTrip">Aller retour</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <div v-if="formDevis.type === 'roundTrip'">
                    <el-col :span="12">
                      <el-form-item label="Date de départ" required prop='depart_date'>
                        <el-input
                          v-model="formDevis.depart_date"
                          type="text"
                          placeholder="Date de départ"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Date de retour" required prop='arrival_date'>
                        <el-input
                          v-model="formDevis.arrival_date"
                          type="text"
                          placeholder="Date de retour"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                  </div>

                  <div v-else>
                    <el-col :span="12">
                      <el-form-item label="Date de départ" required prop='depart_date'>
                        <el-input
                          v-model="formDevis.depart_date"
                          type="text"
                          placeholder="Date de départ"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                  </div>
                  <div>
                    <el-col :span="12">
                      <el-form-item label="Ville de départ" prop='depart_city'>
                        <Autocomplete
                          id="depart_city"
                          v-model="formDevis.depart_city"
                        ></Autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Ville d'arrivée" prop='arrival_city'>
                        <Autocomplete
                          id="arrival_city"
                          v-model="formDevis.arrival_city"
                        ></Autocomplete>
                      </el-form-item>
                    </el-col>
                  </div>
                  <br>
                  <el-form-item label="Vos coordonnées de contact:">
                    <el-col :span="12">
                      <el-form-item label="Prénom" required prop='firstname'>
                        <el-input type="text" v-model="formDevis.firstname"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Nom" required prop='lastname'>
                        <el-input type="text" v-model="formDevis.lastname" required />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="E-mail" required prop='email'>
                        <el-input
                          type="email"
                          v-model="formDevis.email"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Téléphone" required prop='phone'>
                        <el-input type="text" v-model="formDevis.phone"/>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                  <br><br>
                  <div>
                    <el-col :span="5">
                      <el-form-item label="Nombre de moto" required prop='moto_nb'>
                        <el-input
                          type="text"
                          v-model.number="formDevis.moto_nb"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item label="Modèle de chaque moto" required prop='moto_model'>
                        <el-input
                          type="text"
                          v-model="formDevis.moto_model"
                        />
                      </el-form-item>
                    </el-col>
                  </div>
                  <div>
                    <el-col :span="7">
                      <el-form-item label="Nombre de voyageurs(≤20)" required prop='passenger_nb'>
                        <el-input
                          type="text"
                          v-model.number="formDevis.passenger_nb"
                          @input="generatePassengerList"
                        />
                      </el-form-item>
                    </el-col>
                  </div>
                  <div
                    v-for="(passenger, index) in formDevis.passenger_list"
                    :key="index"
                  >
                    <el-form-item v-if="index === 0" label="Liste des voyageurs (facultatif):"></el-form-item>
                    <el-col :span="6">
                      <el-form-item label="Prénom">
                        <el-input type="text" v-model="passenger.firstname" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Nom">
                        <el-input type="text" v-model="passenger.lastname" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="E-mail">
                        <el-input type="text" v-model="passenger.email" />
                      </el-form-item>
                    </el-col>
                  </div>
                  <el-col :span="24">
                      <el-checkbox-group v-model="formDevis.insurance">
                        <el-checkbox value="true" name="insurance">
                          Assurance annulation
                        </el-checkbox>
                        <el-alert type="info" :closable="false">
                          Avec l'assurance annulation: Annulation et remboursement intégral possible jusqu'à 48h avant le départ, hors coût de l'assurance annulation qui représente 10% du billet avec un minimum de 50 euros.
                        </el-alert>
                      </el-checkbox-group>
                  </el-col>
                  <br /><br />
                  <el-col :span="24">
                    <el-form-item label="Code promo">
                      <el-input type="text" v-model="formDevis.code" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="Comment avez-vous entendu parler de nous ?">
                      <el-input
                        type="textarea"
                        v-model="formDevis.how"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="Commentaire">
                      <el-input
                        type="textarea"
                        v-model="formDevis.commentary"
                      />
                    </el-form-item>
                  </el-col>
                  <button
                    class="btn-trans-blue w-1"
                    type="button"
                    @click="sendDevis()"
                  >
                    Je demande un devis
                  </button>
                </el-form>
              </div>
              
        <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align-last: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
          </div>
            </div>
          </div>
          <hr />
        </div>
        <div v-if="currentTab == 3">
          <div class="user-trajectories">
            <el-switch
              v-model="tmpVal"
              size='large'
              inline-prompt
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949;"
              active-text="A venir"
              inactive-text="Réalisé"
            />
            <br>
              <div class="results">
                <div class='result-container'>
                <div class="card-trajectory">
                  <div class="card-trajectory-header">
                    <div class="card-trajectory-header-date">
                      {{ fullDateFR('20240124') }}
                    </div>
                    <div class="card-trajectory-header-info">
                      <div style="padding: 5px 20px; margin-right: 15px;border-radius: 10px; background: gray; color: white;">Facture</div>
                    </div>
                  </div>
                  <div class="card-trajectory-info">
                    <div class="card-trajectory-info-first">
                      <div class="card-trajectory-info-trajectory">
                        <b><span style="color: black">Paris</span></b>
                        <span class="material-symbols-outlined rose">east</span><b><span style="color: black">Lyon</span></b>
                      </div>
                      <div class="card-trajectory-info-services">
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise 220v"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prise 220v"
                              >power</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise USB"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prises USB"
                              >usb</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Café"
                        >
                          <template #reference>
                            <span class="material-symbols-outlined" title="Café"
                              >coffee</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Jeux de société"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Jeux de société"
                              >playing_cards</span
                            >
                          </template>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                <div>
                  <span class="rose"><b>Moto(s):</b></span> 2
                  <br> - test<br> - test 2
                  <br/><br>
                  <span class="rose"><b>Passager(s):</b></span> 2
                  <br> - Test TEST<br> - Test TEST 2
                  <br/><br>
                  <span class="rose"><b>Assurance:</b></span> Non
                </div>
                </div>
              </div>
            </div>
            <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align-last: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
          </div>
            </div>
        </div>
        <div v-if="currentTab == 4">
          <div class="user-trajectories">
            <el-switch
              v-model="tmpVal"
              size='large'
              inline-prompt
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949;"
              active-text="Payé"
              inactive-text="En attente de paiement"
            />
            <br>
              <div class="results">
                <div class='result-container'>
                <div class="card-trajectory">
                  <div class="card-trajectory-header">
                    <div class="card-trajectory-header-date">
                      {{ fullDateFR('20240910') }}
                    </div>
                    <div class="card-trajectory-header-info">
                      <div style="padding: 5px 20px; margin-right: 15px;border-radius: 10px; background: #000047; color: white;">Devis</div>
                      <div style="padding: 5px 20px; margin-right: 15px;border-radius: 10px; background:#000047; color: white;">Payer</div>
                    </div>
                  </div>
                  <div class="card-trajectory-info">
                    <div class="card-trajectory-info-first">
                      <div class="card-trajectory-info-trajectory">
                        <b><span style="color: black">Paris</span></b>
                        <span class="material-symbols-outlined rose">east</span><b><span style="color: black">Lyon</span></b>
                      </div>
                      <div class="card-trajectory-info-services">
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise 220v"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prise 220v"
                              >power</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise USB"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prises USB"
                              >usb</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Café"
                        >
                          <template #reference>
                            <span class="material-symbols-outlined" title="Café"
                              >coffee</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Jeux de société"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Jeux de société"
                              >playing_cards</span
                            >
                          </template>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                <div>
                  <span class="rose"><b>Moto(s):</b></span> 2
                  <br> - test<br> - test 2
                  <br/><br>
                  <span class="rose"><b>Passager(s):</b></span> 2
                  <br> - Test TEST<br> - Test TEST 2
                  <br/><br>
                  <span class="rose"><b>Assurance:</b></span> Non
                  <br/><br>
                  <span class="rose"><b>Détails:</b></span>
                  <br> - Assurance: 0€<br> - Place moto: 213€ x2  
                  <br/><br>
                  <span class="rose"><b>Prix total:</b></span> 426€
                </div>
                </div>
              </div>
            </div>
            <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align-last: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
          </div>
            </div>
        </div>
        <div v-if="currentTab == 5">
          <div class="user-trajectories">
              <div class="flex-col" >
                <el-form
                  ref="ruleDevisRef"
                  style="max-width: 600px"
                  :model="formDevis"
                  :rules="rulesDevis"
                  label-width="auto"
                  label-position="top"
                  class="form"
                  status-icon
                >
                  <div>
                    <el-form-item label="" required prop='type'>
                      <el-radio-group v-model="formDevis.type">
                        <el-radio value="oneWay">Madame</el-radio>
                        <el-radio value="roundTrip">Monsieur</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <div >
                    <el-col :span="24">
                      <el-form-item label="Adresse e-mail" required prop='depart_date'>
                        <el-input
                          v-model="t"
                          type="text"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-form-item label="Mot de passe(A remplir uniquement en cas de changement d'adresse e-mail OU mot de passe)">
                        <el-input
                          v-model="t"
                          type="text"
                          placeholder=""
                          style="width: 100%"
                        />
                      </el-form-item>
                      <el-form-item label="Confirmation mot de passe(A remplir uniquement en cas de changement d'adresse e-mail OU mot de passe)">
                        <el-input
                          v-model="t"
                          type="text"
                          placeholder=""
                          style="width: 100%"
                        />
                      </el-form-item>
                  </div>
                    <el-col :span="8">
                      <el-form-item label="Prénom" required prop='firstname'>
                        <el-input type="text" v-model="t"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Nom" required prop='lastname'>
                        <el-input type="text" v-model="t" required />
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Téléphone" required prop='phone'>
                        <el-input type="text" v-model="t"/>
                      </el-form-item>
                    </el-col>
                  <br><br>
                  <div>
                    <el-col :span="24">
                      <el-form-item label="Nom Entreprise" >
                        <el-input
                          type="text"
                          v-model="t"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Adresse Entreprise" >
                        <el-input
                          type="text"
                          v-model="t"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Numéro de TVA" >
                        <el-input
                          type="text"
                          v-model="t"
                        />
                      </el-form-item>
                    </el-col>
                  </div>
                  <br /><br />
                  <button
                    class="btn-trans-blue w-1"
                    type="button"
                    @click="sendDevis()"
                  >
                    Enregistrer
                  </button>
                </el-form>
              </div>
              
        
            <el-switch
              v-model="tmpVal"
              size='large'
              inline-prompt
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949;"
              active-text="Payé"
              inactive-text="En attente de paiement"
            />
            <br>
              <div class="results">
                <div class='result-container'>
                <div class="card-trajectory">
                  <div class="card-trajectory-header">
                    <div class="card-trajectory-header-date">
                      {{ fullDateFR('20240910') }}
                    </div>
                    <div class="card-trajectory-header-info">
                      <div style="padding: 5px 20px; margin-right: 15px;border-radius: 10px; background: #000047; color: white;">Devis</div>
                      <div style="padding: 5px 20px; margin-right: 15px;border-radius: 10px; background:#000047; color: white;">Payer</div>
                    </div>
                  </div>
                  <div class="card-trajectory-info">
                    <div class="card-trajectory-info-first">
                      <div class="card-trajectory-info-trajectory">
                        <b><span style="color: black">Paris</span></b>
                        <span class="material-symbols-outlined rose">east</span><b><span style="color: black">Lyon</span></b>
                      </div>
                      <div class="card-trajectory-info-services">
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise 220v"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prise 220v"
                              >power</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise USB"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prises USB"
                              >usb</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Café"
                        >
                          <template #reference>
                            <span class="material-symbols-outlined" title="Café"
                              >coffee</span
                            >
                          </template>
                        </el-popover>
                        
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Jeux de société"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Jeux de société"
                              >playing_cards</span
                            >
                          </template>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                <div>
                  <span class="rose"><b>Moto(s):</b></span> 2
                  <br> - test<br> - test 2
                  <br/><br>
                  <span class="rose"><b>Passager(s):</b></span> 2
                  <br> - Test TEST<br> - Test TEST 2
                  <br/><br>
                  <span class="rose"><b>Assurance:</b></span> Non
                  <br/><br>
                  <span class="rose"><b>Détails:</b></span>
                  <br> - Assurance: 0€<br> - Place moto: 213€ x2  
                  <br/><br>
                  <span class="rose"><b>Prix total:</b></span> 426€
                </div>
                </div>
              </div>
            </div>
            <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align-last: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
          </div>
            </div>
        </div>
      </div>
    </div>
    <el-dialog title="Pré-réservation" v-model="showReservationPopup">
                            <el-form
                  ref="ruleReservationRef"
                  style="max-width: 600px; padding: 50px;"
                  :model="formReservation"
                  :rules="rulesReservation"
                  label-width="auto"
                  label-position="top"
                  status-icon
                >
                <div class="loading-container-reservation" v-if="isReservationSending">
                  <div class="loading-spinner-reservation"></div>
                </div>
                  <el-col :span="12">
                    <el-form-item label="Ville de départ" prop='depart'>
                      <Autocomplete :key='refreshAutocomplete' :disabled="formReservation.depart ? true : false"
                        v-model="formReservation.depart"
                      ></Autocomplete>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Ville d'arrivée" prop='arrival'>
                      <Autocomplete :key='refreshAutocomplete' :disabled="formReservation.arrival ? true : false"
                        v-model="formReservation.arrival"
                      ></Autocomplete>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Date de départ" required prop='date_depart'>
                      <el-input
                        v-model="formReservation.date_depart"
                        type="text"
                        placeholder="Date de départ"
                        style="width: 100%"
                        :disabled="formReservation.date_depart ? true : false"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="Prix TTC par moto" required>
                      <el-input
                        type="text"
                        v-model="formReservation.price"
                        :disabled='true'
                      />
                      <el-alert type="info" show-icon :closable="false">
                        <p>Si le tarif de groupe est applicable, il sera affiché dans notre réponse au devis.</p>
                      </el-alert>
                    </el-form-item>
                  </el-col>
                  <br>
                  <el-form-item label="Vos coordonnées de contact:">
                    <el-col :span="12">
                      <el-form-item label="Prénom" required prop='firstname'>
                        <el-input type="text" v-model="formReservation.firstname"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Nom" required prop='lastname'>
                        <el-input type="text" v-model="formReservation.lastname" required />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="E-mail" required prop='email'>
                        <el-input
                          type="email"
                          v-model="formReservation.email"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Téléphone" required prop='phone'>
                        <el-input type="text" v-model="formReservation.phone"/>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                  <br>
                  <div>
                    <el-col :span="5">
                      <el-form-item label="Nombre de moto" required prop='moto_nb'>
                        <el-input
                          type="text"
                          v-model.number="formReservation.moto_nb"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="19">
                      <el-form-item label="Modèle de chaque moto" required prop='moto_model'>
                        <el-input
                          type="text"
                          v-model="formReservation.moto_model"
                        />
                      </el-form-item>
                    </el-col>
                  </div>
                  <div>
                    <el-col :span="24">
                      <el-form-item label="Nombre de voyageurs(≤20)" required prop='passenger_nb'>
                        <el-input
                          type="text"
                          v-model.number="formReservation.passenger_nb"
                          @input="generatePassengerListForReservation"
                        />
                        <el-alert type="info" show-icon :closable="false">
                          <p>Si le nombre de voyageurs > nombre de motos, des frais supplémentaires seront appliqués.</p>
                        </el-alert>
                      </el-form-item>
                    </el-col>
                  </div>
                  <div
                    v-for="(passenger, index) in formReservation.passenger_list"
                    :key="index"
                  >
                    <el-form-item v-if="index === 0" label="Liste des voyageurs (facultatif):"></el-form-item>
                    <el-col :span="6">
                      <el-form-item label="Prénom">
                        <el-input type="text" v-model="passenger.firstname" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="Nom">
                        <el-input type="text" v-model="passenger.lastname" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="E-mail">
                        <el-input type="text" v-model="passenger.email" />
                      </el-form-item>
                    </el-col>
                  </div>
                  <el-col :span="24">
                    <el-checkbox-group v-model="formReservation.insurance">
                      <el-checkbox value="true" name="insuranceReservation">
                        Assurance annulation
                      </el-checkbox>
                      <el-alert type="info" :closable="false">
                        Avec l'assurance annulation: Annulation et remboursement intégral possible jusqu'à 48h avant le départ, hors coût de l'assurance annulation qui représente 10% du billet avec un minimum de 50 euros.
                      </el-alert>
                    </el-checkbox-group>
                  </el-col>
                  <br /><br />
                  <el-col :span="24">
                    <el-form-item label="Code promo">
                      <el-input type="text" v-model="formReservation.code" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="Comment avez-vous entendu parler de nous ?">
                      <el-input
                        type="textarea"
                        v-model="formReservation.how"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="Commentaire">
                      <el-input
                        type="textarea"
                        v-model="formReservation.commentary"
                      />
                    </el-form-item>
                  </el-col>
                  <button
                                  class="btn-trans-blue w-1"
                                  type="button"
                                  @click="sendReservation()"
                                >
                                  Je pré-réserve
                                </button>
                </el-form>
                          </el-dialog>


    <el-dialog title="Comment ça fonctionne ?" v-model="showHelpPopup">
      <div class="trajectory-how">
                        <br />
                        <p></p>
                        <p>
                          <strong
                            >Sur un trajet déjà défini, n'hésitez pas à faire
                            une demande si vous souhaitez un autre point de
                            chargement et/ou de déchargement</strong
                          >
                          en cliquant sur «&nbsp;autre trajet souhaité sur ce
                          parcours/demande de devis&nbsp;»
                        </p>
                        <br />
                        <p>
                          Les trajets à l'international pour 2024 ne sont
                          disponibles que pour les groupes.
                        </p>
                        <br />
                        <p><strong>Grâce au moteur de recherche :</strong></p>
                        <br />
                        <ol>
                          <li>
                            Soit vous cliquez sur Rechercher pour voir tous les
                            trajets Symone disponibles.
                          </li>
                          <li>
                            Soit vous rentrez une date et/ou une ville de départ
                            puis cliquez sur Rechercher pour trouver les trajets
                            à proximité.
                          </li>
                          <li>
                            Cliquez sur Détails pour obtenir plus
                            d'informations, le comparatif de prix avec le même
                            trajet sur sa moto et cliquez sur Je pré-réserve
                            pour accéder au formulaire.
                          </li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>
                          <b
                            >Si vous ne trouvez pas de trajets correspondants,
                            faites une demande avec votre trajet souhaité dans
                            le formulaire ci-dessus, aux dates et lieux de votre
                            choix.
                          </b>
                        </p>
                        <br />
                        <p>
                          <b
                            >Nous avons plusieurs Symone moto en circulation
                            pour répondre à votre demande.</b
                          >
                        </p>
                        <br />
                        <p>
                          Si un trajet vous intéresse mais que vous avez des
                          contraintes particulières (seulement une portion du
                          trajet, horaires, etc), contactez-nous : 06 17 33 43
                          93
                        </p>
                        <br />
                        <p>
                          NB : un tarif spécifique pourrait être fait pour des
                          motos de + de 300kg
                        </p>
                        <p></p>
                      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "@/components/Autocomplete.vue";
import config from "@/config";
import { ElNotification } from 'element-plus'
import { getCountryAbbreviation } from '@/utils/countryUtils';
import { useHead } from '@vueuse/head'

export default {
  name: "SymoneMoto",
  components: {
    Autocomplete
  },
metaInfo() {
return{
title: 'Moto - Symone',
    meta: [
      {
        name: 'description',
        content: "Confiez à Symone vos trajets longue distance sur autoroutes. Vous et votre moto arriverez à destination dans les meilleures conditions pour profiter du plaisir de piloter là où le meilleur de votre passion peut s'exercer. Transport de motos seules sous conditions."
      },{
        name: 'keywords',
        content: "transport moto, moto, transport"
      }
    ]
  }},
  data() {
    return {
meta: {
        title: "Moto - Symone",
        keywords: "transport moto, moto, transport",
        description : "Confiez à Symone vos trajets longue distance sur autoroutes. Vous et votre moto arriverez à destination dans les meilleures conditions pour profiter du plaisir de piloter là où le meilleur de votre passion peut s'exercer. Transport de motos seules sous conditions."
      },
      about: true,
      tagBg1:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      tagBg2:
        "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1",
      tagBg3:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      tagBg4:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      tagBg5:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      subMenuTrajectory: "color: #44bbc3",
      subMenuDevis: "color: white",
      currentSubMenuTrajectory: true,
      currentTab: 2,
      items: [],
      currentIndex: 0,
      currentTag: 0,
      slideIndex: 0,
      search: {
        depart: "",
        arrival: "",
        date: "",
      },
      formDevis: {
        type: "oneWay",
        depart_date: null,
        depart_city: "",
        arrival_date: null,
        arrival_city: "",
        moto_nb: 0,
        moto_model: "",
        distance: "",
        passenger_nb: 0,
        passenger_list: [],
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        code: "",
        how: "",
        commentary: "",
        insurance: [],
      },
      formReservation: {
        price: "",
        date_depart: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_model: "",
        passenger_nb: 0,
        passenger_list: [],
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        code: "",
        how: "",
        commentary: "",
        id: "",
        title: "",
        trajectory: null,
        insurance: [],
      },
      results: [],
      tmpResults: [],
      collapseStatus: [],
      currentPage: 1,
      pageSize: 15,
      partnersLogo: [
        {src: require("@/assets/images/ui/a75e3a15-a873-4baa-a96c-d0601bde9b32.png"), link: "https://liberty-rider.com/fr", alt: "Liberty Rider"},
        {src: require("@/assets/images/ui/MDM-LOGO-RECTANGLE-BLEU-LIBERTE PARTAGEE -ASSUREE.png"), link: "https://www.mutuelledesmotards.fr/", alt: "Mutuelle des motards"},
        {src: require("@/assets/images/ui/logo-bimpair-menu.png"), link: "https://bimpair.com/", alt: "Bimp Air"},
        {src: require("@/assets/images/ui/axa_passion_logo_solid-blue.png"), link: "https://passion.axa.fr/", alt: "AXA Passion"},
      ],
      isLoading: false,
      isDevisSending: false,
      isReservationSending: false,
      showReservationPopup: false,
      showHelpPopup: false,
      refreshAutocomplete: 0,
      rulesDevis:{
        type: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'change' },
        ],
        depart_date: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        arrival_date: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        moto_nb: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        moto_model: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        passenger_nb: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        firstname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        lastname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Veuillez saisir une adresse E-mail valide', trigger: ['blur', 'change'] },
        ],
        phone: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
      },
      rulesReservation:{
        price: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        date_depart: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        moto_nb: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        moto_model: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        passenger_nb: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        firstname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        lastname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Veuillez saisir une adresse E-mail valide', trigger: ['blur', 'change'] },
        ],
        phone: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
      },
    }
  },
  async created(){
    await useHead({
      title: this.meta.title,
      htmlAttrs: { 
        lang: 'en', 
        amp: true,
      },
      meta: [
          {
              name: 'keywords',
              content: this.meta.keywords
          },{
              name: "description",
              content: this.meta.description
          }
      ]
    });
    await this.getResults();
    if(this.$route.query.searchOptionsByHome && this.$route.query.searchOptionsByHome.length > 0) {
      this.search.depart = this.$route.query.searchOptionsByHome[0]
      this.search.arrival = this.$route.query.searchOptionsByHome[1]
      this.searchCities();
      this.refreshAutocomplete++;
    }
  },
  watch:{
    currentSubMenuTrajectory(value){
      if(!value) {
        this.$nextTick(() => {
          let targetElement = document.getElementById('devis-title');
          if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
      }
    }
  },
  computed: {
    totalItems() {
      return this.tmpResults.length;
    },
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      if (this.tmpResults.slice(startIndex, endIndex).length > 0) {
        if(!this.checkDate(this.tmpResults.slice(startIndex, endIndex)[0].date)){
          this.tmpResults.slice(startIndex, endIndex)[0]['first'] = true;
        }
      }
      return this.tmpResults.slice(startIndex, endIndex);
    },
  },
  async mounted() {
await useHead({
      title: this.meta.title,
      htmlAttrs: { 
        lang: 'en', 
        amp: true,
      },
      meta: [
          {
              name: 'keywords',
              content: this.meta.keywords
          },{
              name: "description",
              content: this.meta.description
          }
      ]
    });
    await this.getResults();
    const urlParams = new URLSearchParams(window.location.search);
    const aValue = urlParams.get("currentTab"); 
    if (aValue !== null) {
      this.switchTab(aValue);
    }
    const tabToOpen = urlParams.get('tab');
    if (tabToOpen == 'tab-devis') {
        const tab = document.querySelector(`#tab-devis`);
        if (tab) {
            tab.click();
            this.$nextTick(() => {
              let targetElement = document.getElementById('devis-title');
              if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
        }
    }
  },
  methods: {
    backTop(){
      this.$nextTick(() => {
        let targetElement = document.getElementById('results');
        if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    },
    showCountryAbbreviation(name){
      return getCountryAbbreviation(name.trim());
    },
    openReservationPopup(row){
      this.formReservation = {
        price: "",
        date_depart: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_model: "",
        passenger_nb: 0,
        passenger_list: [],
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        code: "",
        how: "",
        commentary: "",
        id: "",
        title: "",
        trajectory: null,
        insurance: [],
      }

      if(row.type == 'event'){
        this.formReservation.price = `Tarif à calculer par notre service(Event: ${row.name})`;
        this.formReservation.title = 'Event - ' + row.name;
        this.formReservation.id = row.id;
      }
      else{

      this.formReservation.title = `${row.depart}${row.layover1 ? ' → ' + row.layover1 : ''}${row.layover2 ? ' → ' + row.layover2 : ''}${row.layover3 ? ' → ' + row.layover3 : ''}→${row.arrival}`
      this.formReservation.id = row.id;
      this.formReservation.date_depart = this.formatDate(row.date);
      this.formReservation.trajectory = row;

      const radios = document.querySelectorAll(`input[name="optionPrice-${row.id}"]`);
  
      for (const radio of radios) {
        if (radio.checked) {
          switch(radio.value){
            case '0-1':
              this.formReservation.depart = row.depart;
              this.formReservation.arrival = row.layover1;
              if(/^\d+$/.test(row.price0_1)){
                if(!row.discount_value) this.formReservation.price = row.price0_1 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price0_1) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price0_1) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price0_1;
              }
              break;
            case '0-2':
              this.formReservation.depart = row.depart;
              this.formReservation.arrival = row.layover2;
              if(/^\d+$/.test(row.price0_2)){
                if(!row.discount_value) this.formReservation.price = row.price0_2 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price0_2) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price0_2) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price0_2;
              }
              break;
            case '0-3':
              this.formReservation.depart = row.depart;
              this.formReservation.arrival = row.layover3;
              if(/^\d+$/.test(row.price0_3)){
                if(!row.discount_value) this.formReservation.price = row.price0_3 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price0_3) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price0_3) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price0_3;
              }
              break;
            case '0-4':
              this.formReservation.depart = row.depart;
              this.formReservation.arrival = row.arrival;
              if(/^\d+$/.test(row.price0_4)){
                if(!row.discount_value) this.formReservation.price = row.price0_4 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price0_4) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price0_4) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price0_4;
              }
              break;
            case '1-2':
              this.formReservation.depart = row.layover1;
              this.formReservation.arrival = row.layover2;
              if(/^\d+$/.test(row.price1_2)){
                if(!row.discount_value) this.formReservation.price = row.price1_2 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price1_2) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price1_2) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price1_2;
              }
              break;
            case '1-3':
              this.formReservation.depart = row.layover1;
              this.formReservation.arrival = row.layover3;
              if(/^\d+$/.test(row.price1_3)){
                if(!row.discount_value) this.formReservation.price = row.price1_3 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price1_3) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price1_3) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price1_3;
              }
              break;
            case '1-4':
              this.formReservation.depart = row.layover1;
              this.formReservation.arrival = row.arrival;
              if(/^\d+$/.test(row.price1_4)){
                if(!row.discount_value) this.formReservation.price = row.price1_4 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price1_4) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price1_4) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price1_4;
              }
              break;
            case '2-3':
              this.formReservation.depart = row.layover2;
              this.formReservation.arrival = row.layover3;
              if(/^\d+$/.test(row.price2_3)){
                if(!row.discount_value) this.formReservation.price = row.price2_3 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price2_3) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price2_3) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price2_3;
              }
              break;
            case '2-4':
              this.formReservation.depart = row.layover1;
              this.formReservation.arrival = row.arrival;
              if(/^\d+$/.test(row.price2_4)){
                if(!row.discount_value) this.formReservation.price = row.price2_4 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price2_4) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price2_4) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price2_4;
              }
              break;
            case '3-4':
              this.formReservation.depart = row.layover3;
              this.formReservation.arrival = row.arrival;
              if(/^\d+$/.test(row.price3_4)){
                if(!row.discount_value) this.formReservation.price = row.price3_4 + '€';
                else{
                  if(row.discount_type == '1') {
                    this.formReservation.price = parseInt(row.price3_4) * (1 - (parseInt(row.discount_value) / 100));
                    this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}%)`
                  }
                  else {
                    this.formReservation.price = parseInt(row.price3_4) - parseInt(row.discount_value);
                    this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}€)`
                  }
                }
              }
              else{
                this.formReservation.price = row.price3_4;
              }
              break;
            default:
              this.formReservation.price = 'à vérifier par notre service'
              break;
          }
          
          break;
        }
      }
    }
      this.refreshAutocomplete++;
      this.showReservationPopup = true;
    },
  
    async getResults() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/trajectory/get/results`);
        this.results = JSON.parse(JSON.stringify(response.data));
        this.tmpResults = JSON.parse(JSON.stringify(response.data));
        this.currentPage = 1;
        this.backTop();
      } catch (error) {
        console.error("Error results/get:", error);
      }
    },

    
    async searchCities() {
      try {
        this.currentSubMenuTrajectory = true;
        this.subMenuTrajectory = 'color: #44bbc3';
        this.subMenuDevis = 'color: white';

        this.$nextTick(() => {
            let targetElement = document.getElementById('results');
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });

        this.isLoading = true;

        let tmp = JSON.parse(JSON.stringify(this.results));

        if(this.search.depart){
          let cityName = this.search.depart.split(',')[0].trim();
          let countryName = this.search.depart.split(',')[this.search.depart.split(',').length - 1].trim();

          const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${cityName},${countryName}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`;
          const response = await fetch(geocodingApiUrl);
          if (!response.ok) {
            throw new Error('City not found');
          }
          const data = await response.json();

          if (data.length === 0) {
            throw new Error('City not found');
          }

          const { lat, lon } = data[0];

          const overpassQuery = `
              [out:json];
              (
                  node(around:100000,${lat},${lon})[place=city];
                  way(around:100000,${lat},${lon})[place=city];
                  relation(around:100000,${lat},${lon})[place=city];
                  node(around:100000,${lat},${lon})[place=town];
                  way(around:100000,${lat},${lon})[place=town];
                  relation(around:100000,${lat},${lon})[place=town];
              );
              out;
          `;

          const responsesearch = await axios.post('https://overpass-api.de/api/interpreter', overpassQuery);

          const cities = responsesearch.data.elements.map(element => ({
              id: element.id,
              name: element.tags.name
          }));

          tmp = tmp.filter(result => {
              if (!result.depart) {
                  return true;
              }
              return cities.some(city => 
                  city.name?.toLowerCase() === (result.depart?.split(',')[0] || '').toLowerCase() || 
                  city.name?.toLowerCase() === (result.layover1?.split(',')[0] || '').toLowerCase() || 
                  city.name?.toLowerCase() === (result.layover2?.split(',')[0] || '').toLowerCase() || 
                  city.name?.toLowerCase() === (result.layover3?.split(',')[0] || '').toLowerCase()
              );
          });
        }
        if(this.search.arrival){
          let cityName = this.search.arrival.split(',')[0].trim();
          let countryName = this.search.arrival.split(',')[this.search.arrival.split(',').length - 1].trim();

          const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${cityName},${countryName}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`;
          const response = await fetch(geocodingApiUrl);
          if (!response.ok) {
            throw new Error('City not found');
          }
          const data = await response.json();

          if (data.length === 0) {
            throw new Error('City not found');
          }

          const { lat, lon } = data[0];

          const overpassQuery = `
              [out:json];
              (
                  node(around:100000,${lat},${lon})[place=city];
                  way(around:100000,${lat},${lon})[place=city];
                  relation(around:100000,${lat},${lon})[place=city];
                  node(around:100000,${lat},${lon})[place=town];
                  way(around:100000,${lat},${lon})[place=town];
                  relation(around:100000,${lat},${lon})[place=town];
              );
              out;
          `;

          const responsesearch = await axios.post('https://overpass-api.de/api/interpreter', overpassQuery);

          const cities = responsesearch.data.elements.map(element => ({
              id: element.id,
              name: element.tags.name
          }));

          tmp = tmp.filter(result => {
              if (!result.depart) {
                  return true;
              }
              return cities.some(city => 
                  city.name?.toLowerCase() === (result.arrival?.split(',')[0] || '').toLowerCase() || 
                  city.name?.toLowerCase() === (result.layover1?.split(',')[0] || '').toLowerCase() || 
                  city.name?.toLowerCase() === (result.layover2?.split(',')[0] || '').toLowerCase() || 
                  city.name?.toLowerCase() === (result.layover3?.split(',')[0] || '').toLowerCase()
              );
          });
        }
        if(this.search.date){
          const targetDateObject = new Date(`${this.search.date.substring(0, 4)}-${this.search.date.substring(4, 6).padStart(2, '0')}-${this.search.date.substring(6, 8).padStart(2, '0')}`);

          const startDate = new Date(targetDateObject);
          startDate.setDate(startDate.getDate() - 10);

          const endDate = new Date(targetDateObject);
          endDate.setDate(endDate.getDate() + 10);

          tmp = tmp.filter(date => {
              const currentDate = new Date(date.date.substring(0, 4), parseInt(date.date.substring(4, 6)) - 1, date.date.substring(6, 8));
              return currentDate >= startDate && currentDate <= endDate;
          });
        }
      if(tmp.length == 0 || tmp.every(item => item.type === 'event')){
        this.currentSubMenuTrajectory = false;
        this.subMenuDevis = 'color: #44bbc3';
        this.subMenuTrajectory = 'color: white';
        
        this.$nextTick(() => {
          let targetElement = document.getElementById('devis-title');
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
      }else{
        if(tmp.filter(item => item.type === 'trajectory').length <= 5){
          let trajectoryCount = 0;
          let firstEventIndexBeforeTrajectory = -1;
          let lastEventIndexAfterTrajectory = -1;

          for (let i = 0; i < tmp.length; i++) {
              const item = tmp[i];
              if (item.type === 'trajectory') {
                  trajectoryCount++;
              }else { 
                if (trajectoryCount === 0) {
                  firstEventIndexBeforeTrajectory = i;
                }
              }
          }
          if (firstEventIndexBeforeTrajectory !== -1) {
            tmp = tmp.slice(firstEventIndexBeforeTrajectory);
          }
          tmp.reverse();
          trajectoryCount = 0;
          for (let i = 0; i < tmp.length; i++) {
              const item = tmp[i];
              if (item.type === 'trajectory') {
                  trajectoryCount++;
              }else { 
                if (trajectoryCount === 0) {
                  lastEventIndexAfterTrajectory = i;
                }
              }
          }
          
          if (lastEventIndexAfterTrajectory !== -1) {
            tmp = tmp.slice(0, lastEventIndexAfterTrajectory + 1);
          }
          tmp.reverse();
        }
        
        this.tmpResults = tmp;
        this.$nextTick(() => {
          let targetElement = document.getElementById('results');
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
      }
      this.currentPage = 1;
      this.isLoading = false;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
  },


    generatePassengerList() {
      if (this.formDevis.passenger_nb > 20) {
        this.formDevis.passenger_nb = 20;
      }
      this.formDevis.passenger_list = [];
      for (let i = 0; i < this.formDevis.passenger_nb; i++) {
        this.formDevis.passenger_list.push({
          firstname: "",
          lastname: "",
          email: "",
        });
      }
    },
    generatePassengerListForReservation() {
      if (this.formReservation.passenger_nb > 20) {
        this.formReservation.passenger_nb = 20;
      }
      this.formReservation.passenger_list = [];
      for (let i = 0; i < this.formReservation.passenger_nb; i++) {
        this.formReservation.passenger_list.push({
          firstname: "",
          lastname: "",
          email: "",
        });
      }
    },
    async getCityCoordinates(city, country) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${city},${country}`
        );
        const data = await response.json();

        if (data.length > 0) {
          return {
            lat: parseFloat(data[0].lat),
            lon: parseFloat(data[0].lon),
          };
        } else {
          throw new Error("No data found for the given city and country.");
        }
      } catch (error) {
        console.error("Error occurred while fetching city coordinates:", error);
        return null;
      }
    },
    async getRouteDistance(city1, country1, city2, country2) {
      try {
        const coordinates1 = await this.getCityCoordinates(city1, country1);
        const coordinates2 = await this.getCityCoordinates(city2, country2);

        const response = await fetch(
          `https://router.project-osrm.org/route/v1/driving/${coordinates1.lon},${coordinates1.lat};${coordinates2.lon},${coordinates2.lat}?overview=false`
        );
        const data = await response.json();

        if (data.code === "Ok" && data.routes && data.routes.length > 0) {
          const distanceInMeters = data.routes[0].distance;
          const distanceInKilometers = distanceInMeters / 1000;
          return distanceInKilometers;
        } else {
          throw new Error(
            "Unable to find route or invalid response from server."
          );
        }
      } catch (error) {
        console.error("Error occurred while fetching route distance:", error);
        return null;
      }
    },
    async calculateDistance(str1, str2) {
      const city1 = str1.split(",")[0];
      const country1 = str1.split(",")[str1.split(",").length - 1];
      const city2 = str2.split(",")[0];
      const country2 = str2.split(",")[str1.split(",").length - 1];

      const distance = await this.getRouteDistance(
        city1,
        country1,
        city2,
        country2
      );
      if (distance !== null) {
        return `${distance.toFixed(2)}km`;
      } else {
        return "Failed to calculate route distance.";
      }
    },
    async sendReservation() {
      try {
        if (!this.$refs.ruleReservationRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleReservationRef.validate((valid, fields) => {
            if (valid) {
              console.log('submit!');
              resolve(true);
            } else {
              console.log('error submit:' + reject, fields);
              resolve(false);
            }
          });
        });
        if(!check) return;
        this.isReservationSending = true;
        const response = await axios.post(
          `${config.API_BASE_URL}/mail/reservation`,
          this.formReservation
        );window.dataLayer?.push({
        'event': 'conversion',
        'send_to': 'AW-16522766943/ZgX9CI2gkqgZEN_U1cY9'
    });window.dataLayer?.push({
        'event': 'conversion_devis',
        'send_to': 'AW-16522766943/ZgX9CI2gkqgZEN_U1cY9'
    });this.$emit('conversion_devis', 'conversion_devis');
        console.log(response.data);this.$router.push('/form/quotation/succes');
        ElNotification({
          title: 'Success',
          message: 'Demande envoyée, nous vous répondrons dans les plus brefs délais.',
          type: 'success',
          position: 'bottom-right',
          duration: 0
        })

        this.isReservationSending = false;
        this.showReservationPopup = false;
      } catch (error) {
        console.error("Error Send/Devis:", error);
      }
    },
    async sendDevis() {
      try {
        if (!this.$refs.ruleDevisRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleDevisRef.validate((valid, fields) => {
            if (valid) {
              console.log('submit!');
              resolve(true);
            } else {
              console.log('error submit:' + reject, fields);
              resolve(false);
            }
          });
        });
        if(!check) return;
        this.isDevisSending = true;
        if(this.formDevis.depart_city && this.formDevis.arrival_city){
        this.formDevis.distance = await this.calculateDistance(
          this.formDevis.depart_city,
          this.formDevis.arrival_city
        );}
        const response = await axios.post(
          `${config.API_BASE_URL}/mail/devis`,
          this.formDevis
        );window.dataLayer?.push({
        'event': 'conversion',
        'send_to': 'AW-16522766943/ZgX9CI2gkqgZEN_U1cY9'
    });window.dataLayer?.push({
        'event': 'conversion_devis',
        'send_to': 'AW-16522766943/ZgX9CI2gkqgZEN_U1cY9'
    });this.$emit('conversion_devis', 'conversion_devis');
        console.log(response.data);this.$router.push('/form/quotation/succes');
        ElNotification({
          title: 'Success',
          message: 'Demande envoyée, nous vous répondrons dans les plus brefs délais.',
          type: 'success',
          position: 'bottom-right',
          duration: 0
        })
        this.isDevisSending = false;
      } catch (error) {
        console.error("Error Send/Devis:", error);
      }
    },
    formatDate(dateString) {
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6).padStart(2, "0");
      const day = dateString.slice(6, 8).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formatTimestamp(timestamp, nbMoto){
      const [hours, minutes] = timestamp.split(':').map(Number);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      if(nbMoto && nbMoto > 3) date.setMinutes(date.getMinutes() - 30);
      else date.setMinutes(date.getMinutes() - 15);

      const formattedHours = String(date.getHours()).padStart(2, '0');
      const formattedMinutes = String(date.getMinutes()).padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    },
    fullDateFR(dateString){
      const months = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
      ];

      const daysOfWeek = [
          'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'
      ];

      const year = dateString.substring(0, 4);
      const monthIndex = parseInt(dateString.substring(4, 6)) - 1;
      const day = dateString.substring(6, 8);

      const date = new Date(year, monthIndex, day);

      const dayOfWeek = daysOfWeek[date.getDay()];
      const month = months[monthIndex];

      return `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)} ${day} ${month} ${year}`;
    },
    checkDate(dateString) {
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1;
        const todayDay = today.getDate();
        
        const year = parseInt(dateString.substring(0, 4));
        const month = parseInt(dateString.substring(4, 6));
        const day = parseInt(dateString.substring(6, 8));
        const date = new Date(year, month - 1, day);
        
        return date > new Date(todayYear, todayMonth - 1, todayDay);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.collapseStatus = Array.from({ length: this.pageSize }, () => false);
      this.$nextTick(() => {
        let targetElement = document.getElementById('results');
        if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    },
    switchTab(tab) {
      this.currentTab = tab;
      if (tab == 1) {
        this.tagBg1 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 2) {
        this.tagBg2 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 3) {
        this.tagBg3 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 4) {
        this.tagBg4 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 5) {
        this.tagBg5 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }
    },
  },
};
</script>

<style scoped type="scss">
.el-col-1, .el-col-2, .el-col-3, .el-col-4, .el-col-5, .el-col-6, .el-col-7, .el-col-8, .el-col-9, .el-col-10,.el-col-11, .el-col-12, .el-col-13, .el-col-14, .el-col-15, .el-col-16, .el-col-17, .el-col-18, .el-col-19, .el-col-20, .el-col-21, .el-col-22, .el-col-23, .el-col-24 {
  display: inline-block;
  width: 100%;
}
.loading-container, .loading-container-devis, .loading-container-reservation{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ffffffb0;
  z-index: 5;
}

.loading-spinner, .loading-spinner-devis, .loading-spinner-reservation {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.timeline-bloc{
  display: flex;
}
.time {
  color: #909399;
  font-size: 12px;
  margin: -1px 10px 0 0;
}
.timeline-comment{
  margin: 0;
  font-size: 12px;
  color: #909399;
  line-height: none;
}

>>>.el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
    display: inline;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.autocomplete >>> ul {
  z-index: 3;
  color: dimgray;
}
.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.flex-col {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
.form {    
  background: #ffffff75;
    border-radius: 20px;
    margin-top: 50px;

  color: #000047;
  font-size: 20px;
  padding: 50px;
  div {
    position: relative;
  }
}
.w-1 {
  width: 100%;
}
.w-2 {
  width: 50%;
  text-align: center;
}
.border {
  text-shadow: -1px -1px 0 #000047, 1px -1px 0 #000047, -1px 1px 0 #000047,
    1px 1px 0 #000047;
}
.border-white {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}
.rose{
  color: #e6007e !important;
}
.white{
  color: white;
}
.number {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #000047;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 400;
  margin-right: 10px;
}
.partners{
  margin: 40px 0 15px 0;
  background-color: #000047;
  text-align: center;
}
.partners-list{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  img {
    margin: 10px;
  }
  margin-bottom: 10px;
}
>>>.el-alert__content{
  line-height: normal;
}

/**Trajectory */
.trajectory {
  background: linear-gradient(45deg, #00474491, #47450091);
  width: 100%;
  color: white;
  display: flex;
}
.trajectory-search {
  display: inline-block;
  width: 30%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background: linear-gradient(45deg, #ff009796, #050035a3);
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
}
.trajectory-search-partners{
  display: block; 
}
.trajectory-search-options {
  height: -webkit-fill-available;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;

  .trajectory-search-options-card {
    background: #1d2327;
    border-radius: 20px;
    width: 100%;
    padding: 30px;
    text-align: center;
    margin-top: 30px;
  }
}
.trajectory-search-container{
  position: fixed;
  top: 35%;
  z-index: 2;
  border-radius: 20px;
  padding: 20px;
  background: #ffffff3d;
}
.trajectory-results {
  display: inline-block;
  position: relative;
  width: 70%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  overflow-y: auto;
  background: left;
  background-color: #ffffffbf;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
}
.user-trajectories {
  display: inline-block;
  position: relative;
  width: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  overflow-y: auto;
  background: left;
  background-color: #ffffffbf;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
}
.results {
  margin-top: 30px;
  width: 100%;
  max-width: 950px;
  text-align: -webkit-center;
}
>>>.el-pagination{
  place-content: center;
}
>>>.el-pagination.is-background .el-pager li.is-active {
    background-color: #000047;
}
.overlay-blue{
  color: #ffffff82;
    font-size: 60px;
    font-weight: 600;
    position: absolute;
    background: linear-gradient(25deg, #11237db3, #474500b0);
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      transform: rotateZ(338deg);
    }
}
.card-header{
display: flex;
justify-content: space-between;
}
.card-title{
  display: flex;
    align-items: center;
    justify-content: center;
}
.card-trajectory {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 5px 5px 1px #00000030;
  border: 1px solid #9f9f9f;
  .el-collapse {
    border: none !important;
    --el-collapse-header-height: auto;
    --el-collapse-header-font-size: 15px;
    text-align: left !important;
    padding: 10px 30px 10px 30px;
    >>> .el-collapse-item__header {
      text-align: left !important;
      font-weight: 400;
      border: none;
    }
    .btn-blue {
      margin-left: auto;
      font-size: 20px;
      cursor: pointer;
    }
    li, .el-timeline-item{
      cursor: auto;
    }
  }
}
>>>.el-pager{
  position: relative;
}
>>>.el-dialog__body{
  display: flex;
    justify-content: center;
}
    .btn-blue {
      margin-left: auto;
      font-size: 20px;
      cursor: pointer;
    }
.hr-line{
    display: flex;
    width: 100%;
    color: white;
    font-size: 40px;
    flex-direction: column-reverse;
    margin-bottom: 30px;
    text-shadow: -2px -2px 0 #000047, 2px -2px 0 #000047, -2px 2px 0 #000047,
    2px 2px 0 #000047;
}
.card-trajectory-header {
  padding-left: 30px;
  min-height: 58px;
  align-items: center;
  background: #e6007e;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  .card-trajectory-header-info {
    display: flex;
    flex-wrap: nowrap;
  }
}
.card-trajectory-header-info-moto {
  width: 100px;
  background: #44bbc3;
  color: white;
  width: 50px;
  padding: 5px 10px;
  border-radius: 0 0 0 20px;
  text-align: center;
  margin-right: -1px;
  font-weight: 300;
}
.card-trajectory-header-info-passenger {
  border-left: 1px solid #8f8f8f;
  width: 100px;
  background: #44bbc3;
  color: white;
  width: 80px;
  padding: 5px 10px;
  border-radius: 0 20px 0 0;
  text-align: center;
  font-weight: 300;
}
.trajectory-options-discount{
  display: flex;
    flex-wrap: wrap;
    justify-content: right;
}
.card-discount{
    font-size: 22px;
    background: #c50909;
    border-radius: 50px 5px 5px 50px;
    font-weight: 600;
    align-self: center;
    padding: 2px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
}
.card-discount-blue{
  background-color: #000047;
    font-size: 15px;
    border-radius: 50px;
    font-weight: 600;
    align-self: center;
    padding: 2px 10px;
    color: white;
}

.card-discount-red{
  background-color: #c50909;
    font-size: 15px;
    border-radius: 50px;
    font-weight: 600;
    align-self: center;
    padding: 2px 10px;
    color: white;
}
.card-trajectory-info {
  text-align: left;
  background-color: whitesmoke;
  background-size: contain;
  background-position: bottom;
  font-size: 25px;
  padding: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-position: right;
  .card-trajectory-info-first {
    display: flex;
    justify-content: space-between;
  }
  .card-trajectory-info-second {
    font-size: 15px;
  }
  .card-trajectory-info-trajectory{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.card-trajectory-info-services {
  display: inline-block;
  float: right;
  vertical-align: middle;
  text-align: right;
}
.trajectory-plan {
  margin-right:30px;
  display: flex;
  background: #f3f3f3;
  border-radius: 20px;
  position: relative;
  padding: 30px;
  margin: 30px 0;
  ul {
    position: relative;
    padding: inherit;
    user-select: none;
  }
  >>> ul {
    position: relative;
    padding: inherit;
  }
  >>> li {
    background-color: transparent;
    border: none;
  }
}
.trajectory-options {
  margin: 0 auto 0 20px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p{
    display: inline;
    margin-left: 5px;
  }
}
.trajectory-how {
  margin-right:30px;
  position: relative;
  font-size: 20px;
  padding: 0 30px;
}
.trajectory-eco {
  position: relative;
  font-size: 20px;
  border-radius: 20px;
  border: 2px solid black;
  padding: 30px;
    margin-bottom: 50px;
}
.submenu {
  background: #1d2327;
  height: 5px;
  width: 100%;
  z-index: 2;
}
.submenu-bottom {
  width: 100%;
  height: 40px;
  background: #1d2327;
  display: flex;
  position: sticky;
  border-bottom: 2px solid #e6007e;
}
.submenu-option {
  margin: 10px 30px;
  color: white;
  cursor: pointer;
  :hover {
    color: #44bbc3;
  }
}
.circle {
  width: 40px;
  height: 40px;
  margin: 10px 0;
  border-radius: 50%;
}

.about2 {
  background: linear-gradient(45deg, #00474491, #47450091);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align-last: center;
  padding: 50px;
}
.window {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.tags {
  padding-left: 100px;
}
.tag {
  position: relative;
  z-index: 0;
  transition: font-size 0.5s ease;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400px;
  width: 200px;
  padding: 15px 25px 1px 25px;
  border-radius: 10px 10px 0 0;
  border: none;
  border-left: 1px solid #000047;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.5);
  transform: perspective(5px) rotateX(1.5deg);
  transform-origin: bottom;
  div {
    transform: perspective(5px) rotateX(-1.5deg);
    transform-origin: bottom;
  }
}
.tag-child {
  margin-left: -50px;
}
/**line */
.menu a {
  color: white;
  margin: 0 10px;
}
.nav {
  padding-top: 25px;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;
  overflow-x: auto;
}
.nav::-webkit-scrollbar {
  display: none;
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.footer {
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.footer-title {
  font-size: 20px;
  color: #e6007e;
  font-weight: 400;
  text-align-last: center;
}
/**Tag-8 */
.home-tag-8 {
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  background-image: url("../assets/images/home-7.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
.extra-news-list {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* 隐藏IE和Edge的滚动条 */
  scrollbar-width: none; /* 隐藏Firefox的滚动条 */
}

.extra-news-list::-webkit-scrollbar {
  display: none; /* 隐藏Chrome和Safari的滚动条 */
}

.scrollable-container {
  display: flex;
  flex-wrap: nowrap;
}

.card-extra-news {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  flex: 0 0 auto;
  margin-right: 20px; /* 添加间距 */
  width: 300px; /* 设置每个卡片的宽度 */
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
a {
  text-decoration: none;
  color: inherit;
}
/**tag-6 */

.home-tag-6 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
  background-image: url("../assets/images/ui/home-5.png");
  background-position: start;
  background-size: cover;
}
/**tag-7 */
.home-tag-7 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
}
.sponsor-list {
  width: 2000px;
  max-width: 100%;
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位 */
  display: -webkit-inline-box;
  -webkit-box-align: center;
  box-sizing: border-box;
}

.slides-container {
  display: flex; /* 使用 flex 布局 */
  transition: transform 0.5s ease; /* 添加过渡效果 */
  padding: 0 100px;
  overflow: hidden;
  width: 400px;
}

.slides-container img {
  width: 100%; /* 图片宽度占满容器 */
  height: fit-content;
}

.prev-btn,
.next-btn {
  position: absolute; /* 绝对定位 */
  top: 50%; /* 按钮垂直居中 */
  transform: translateY(-50%); /* 调整垂直位置 */
  background: transparent; /* 背景透明 */
  border: none; /* 去掉边框 */
  outline: none; /* 去掉外边框 */
  cursor: pointer; /* 鼠标样式设置为手型 */
}

.prev-btn {
  left: 0; /* 左侧按钮 */
}

.next-btn {
  right: 0; /* 右侧按钮 */
}
/**tag-5 */
.home-tag-5-list {
  display: flex;
  flex-wrap: wrap;
}

.card-price {
  display: flex;
  width: calc(50% - 20px); /* 每个卡片的宽度为父容器的一半 */
  margin: 10px;
  border-radius: 10px;
  background-color: white;
}

.card-image {
  flex: 1;
  border-radius: 10px;
  align-self: center;
  margin-left: 10px;
  max-width: 200px;
}

.card-image img {
  max-width: 200px; /* 图片宽度占满容器 */
  height: auto; /* 高度自适应 */
  border-radius: 10px;
}

.card-content {
  flex: 1;
  padding: 0 10px;
  align-self: center;
}
.home-tag-5 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
}

.firstname {
  color: white;
  font-weight: 400;
  font-size: 40px;
}
.lastname {
  color: white;
  font-weight: 400;
  font-size: 20px;
}
.avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50%; /* 使图像成为圆形 */
}
/**Tag-4 */
.card {
  background-color: #000047;
  background-image: url("../assets/images/ui/aile-bleu-s.png");
  width: 250px;
  height: 400px;
  background-size: 100% auto;
  background-position: start;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 0 10px;
  display: inline-block;
}
.card-bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  place-content: center;
  align-items: center;
}

.card:hover {
  background-image: url("../assets/images/ui/aile-rose.png");
}
.home-tag-4 {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  box-sizing: border-box;
  background-image: url("../assets/images/ui/aile-bleu.png");
  background-position: center;
}

/**Tag-3 */
.home-tag-3 {
  height: 100vh;
  box-sizing: border-box;
}
.material-symbols-outlined {
  font-size: 30px;
  color: dimgray;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
}
.material-symbols-outlined:hover {
  cursor: default;
}

.home-tag-3-loop {
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: transform 0.5s, font-size 0.5s;
  height: 33vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-size: 18px;
}

.active {
  font-size: 24px; /* 点击后放大文字 */
  text-shadow: -2px -2px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* 描边效果，可以根据需要调整阴影的位置和颜色 */
}

.slideshow {
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh; /* 背景图容器的高度 */
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out; /* 背景图过渡效果 */
  position: absolute; /* 相对定位 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
}
/*Tag-2*/
.home-tag-2 {
  width: 100%; /* 宽度占满父容器 */
  height: 100vh; /* 高度占满视口 */
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位，用于放置视频 */
}

.home-tag-2 video {
  position: absolute; /* 绝对定位，覆盖整个容器 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 视频填充整个容器 */
}

.overlay {
  position: absolute; /* 相对定位 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ); /* 添加叠加的半透明背景 */
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  text-align: center;
}

/*tag-1*/
#homeFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  position: relative;
  >>>ul{
    top: 50px;
  }
}
.btn-rose {
  border: none;
  background-color: #e6007e;
  color: white;
  border-radius: 5px;
  padding: 13px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 20px;
      cursor: pointer;
}

.btn-trans-white {
  font-family: "Roboto Condensed", sans-serif;
  background-color: transparent;
  color: white;
  font-size: 20px;
  margin-top: 20px;
  width: 30vw;
  border: 2px solid white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
}
.btn-trans-white:hover {
  background-color: white; /* 悬浮时背景色变为白色 */
  color: black; /* 悬浮时文字变为透明 */
}
.moto-bg {
  background-position: bottom center; /* 垂直和水平居中 */
  background-image: url("../assets/images/photo_2024-03-01_15-47-58.jpg");
  height: 100vh;
  background-size: cover;
  font-family: "Roboto Condensed", sans-serif;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 200px 20px;
}
.home-tag-1-moto {
  background-position: bottom center; /* 垂直和水平居中 */
  background-image: url("../assets/images/iStock-905788418.png");
  height: 30vh;
  background-size: cover;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 200px 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.home-tag-1-filter {
  max-width: 50vw;
  position: static;
}
.home-tag-1-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  float: right;
  height: 100%;
}
.home-tag-1-bottom {
  height: 100%;
}
.title {
  color: white;
  font-size: 50px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 55px;
  margin-bottom: 20px;
}
.home-tag-1-top-title {
  text-align: right;
}
.home-tag-1-bottom-title {
  text-align: left;
}
.details {
  max-width: 40vw;
  color: gray;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}
.home-tag-1-top-details {
  text-align: right;
}
.home-tag-1-bottom-details {
  text-align: left;
}


@media screen and (max-width: 1316px) {
  .trajectory{
    flex-wrap: wrap;
  }
  .trajectory-search{
    width: 100%;
    padding: 50px 10px;
  }
  .trajectory-search-partners{
    display: none; 
  }
  .trajectory-results{
    width: 100%;
    padding: 50px 10px;
  }
  .trajectory-search-container{
    position: relative;
    top: auto;
  }
  .trajectory-plan{
    flex-wrap: wrap;
  }
  .trajectory-options{
    width: 100%;
  }
  .card-trajectory-header{
    padding-left: 10px;
  }
  .card-trajectory-header-info{
    align-self: stretch;
  }
  .card-trajectory-header-info-passenger, .card-trajectory-header-info-moto{
    place-content: center;
  }
  >>>.el-collapse-item__header{
    flex-wrap: wrap;
  }
  .el-col{
    max-width: 99%;
  }
  .el-form{
    padding: 20px !important;
  }
  >>>.el-dialog{
    width: 99%;
  }
  .tags{
    white-space: nowrap;
    overflow-x: auto;
    padding-left: 0;
  }
  .tags::-webkit-scrollbar {
    display: none;
  }
  .nav{
    justify-content: left;
  }
  .nav img{
    margin-left: 10px !important;
  }
  .footer img{
    width: 100%;
    max-width: 600px;
  }
}
</style>
